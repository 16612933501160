import { useRouter } from 'next/router'

const useEventScope = () => {
  const { pathname = '/' } = useRouter()

  const sharedEventsObject = {
    '/': 'Home',
    '/about': 'About',
    '/about/press': 'Press',
    '/about/press/press-kit': 'Press Kit',
    '/about/member-stories': 'Member Stories',
    '/about/contact-us': 'Contact Us',
    '/home-equity-loan': 'Home Equity Loans',
    '/licenses': 'Licenses',
    '/personal-loans': 'Personal Loans',
    '/policies': 'Policies',
    '/resolve-debt': 'Resolve Debt',
    '/sms-disclosures': 'SMS Disclosure',
    '/home-equity-loan/frequently-asked-questions': 'PL FAQ',
    '/personal-loans/frequently-asked-questions': 'HEL FAQ',
    '/resolve-debt/frequently-asked-questions': 'RD FAQ',
    '/learn': 'Learn',
    '/about/blog': 'Blog',
    '/dollars-sense': 'Dollars Sense',
    '/debt-consolidation': 'Debt Consolidation',
  }

  return sharedEventsObject[pathname] !== undefined ? sharedEventsObject[pathname] : ''
}

export { useEventScope }
