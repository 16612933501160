export const CONTENT_TYPES = {
  PAGE: 'page',
  LANDING: 'landingPage',
  ADVERTORIAL: 'advertorial',
  PRESS_STORY: 'pressStory',
  ARTICLE: 'article',
  CORPORATE_BLOG: 'corporateBlog',
  CONSUMER_BLOG: 'consumerBlog',
  PERMALINK_MEDIA: 'permalinkMedia',
  FFAM_BANNER: 'ffamBanner',
  HEADER: 'header',
  FOOTER: 'footer',
  EMERGENCIES_BANNER: 'emergenciesBanner',
  EXTERNAL_LINK: 'externalLink',
  JSON_FIELD: 'jsonField',
  AUTHOR: 'authorName',
  ARTICLE_FULL: 'Article',
  CORPORATE_BLOG_FULL: 'CorporateBlog',
  CONSUMER_BLOG_FULL: 'ConsumerBlog',
  EXTERNAL_LINK_FUll: 'ExternalLink',
  PAGE_SECTION: 'pageSection',
  LONG_FORM_CONTENT: 'longFormContent',
  LONG_FORM_CONTENT_FULL: 'LongFormContent',
}

export const ARTICLES_CONTENT_TYPES = {
  ARTICLE: 'Article',
  CORPORATE_BLOG: 'Corporate Blog',
  CONSUMER_BLOG: 'Consumer Blog',
}

export const LANGUAGES = {
  DEFAULT: 'en-US',
}

export const PAGE_LIMIT_DEFAULT = 9
export const AUTHOR_LIMIT_DEFAULT = 10
export const AUTHOR_LIMIT_ARTICLES_DEFAULT = 5
export const NESTED_REFERENCE_RESOLUTION_DEPTH = 10
export const PAGE_LIMIT_ARTICLES_DEFAULT = 10

export const ASSOCIATED_PRODUCT_LIST_KEBAB_CASE = [
  'resolve-debt',
  'personal-loans',
  'home-equity-loans',
  'debt-consolidation',
]

export const ASSOCIATED_PRODUCT_LIST = {
  'resolve-debt': 'Debt Resolution',
  'personal-loans': 'Personal Loans',
  'home-equity-loans': 'Home Equity Loans',
  'debt-consolidation': 'Debt Consolidation',
}

/**
 * @note This constant provides the respective links for each product page,
 * taking into account the discrepancy between the page path [/home-equity-loan]
 * and the associated product name [Home Equity Loans] (with S) for the articles,
 * difference from ASSOCIATED_PRODUCT_LIST since it does not take this discrepancy
 * into account and is used for another purpose such as providing the url of
 * the page category
 */
export const ASSOCIATED_PRODUCT_PAGE_LIST = {
  'resolve-debt': 'Debt Resolution',
  'personal-loans': 'Personal Loans',
  'home-equity-loan': 'Home Equity Loans',
  'debt-consolidation': 'Debt Consolidation',
}
