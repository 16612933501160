const TRACK_EVENTS = {
  INTERNAL_LINK_CLICK: 'internal_campaign_click',
  PAGE_NAV_CLICK: 'page_nav_click',
  BUTTON_CLICK: 'button_click',
  LINK_CLICK: 'link_click',
  UI_CLICK: 'ui_click',
}

/* Button Click */
const BUTTON_CLICK = {
  click_type: 'Button Click',
  event_action: TRACK_EVENTS.BUTTON_CLICK,
  event_type: TRACK_EVENTS.BUTTON_CLICK,
  track_event: TRACK_EVENTS.BUTTON_CLICK,
}

/* Link Click */
const LINK_CLICK = {
  click_type: 'Link Click',
  event_action: TRACK_EVENTS.LINK_CLICK,
  event_type: TRACK_EVENTS.LINK_CLICK,
  track_event: TRACK_EVENTS.LINK_CLICK,
}

/* UI Click */
const UI_CLICK = {
  click_type: 'UI Click',
  event_action: TRACK_EVENTS.UI_CLICK,
  event_type: TRACK_EVENTS.UI_CLICK,
  track_event: TRACK_EVENTS.UI_CLICK,
}

const presetMap = { 'Button Click': BUTTON_CLICK, 'Link Click': LINK_CLICK, 'UI Click': UI_CLICK }

export { TRACK_EVENTS, BUTTON_CLICK, LINK_CLICK, UI_CLICK, presetMap }
