import { UPDATE_TRACK_EVENT_LIST as events } from 'utils/analytics/sync-event-list'
import { presetMap } from 'utils/analytics/trackingLibrary/event-presets'

const removeUnassigned = (track) => {
  // Remove the list_name property from the track object
  delete track.list_name
  // Remove unassigned tracking app data
  for (const key in track) {
    if (track[key] === '[runtime]' || track[key] === '' || typeof track[key] === 'undefined') {
      delete track[key]
    }
  }
  return track
}

// Spreads event presets
const addPresets = (track) => {
  let presets = presetMap[track.event_type]
  if (presets) {
    track = { ...track, ...presets }
  }
  return track
}

/**
 * Updates the track object with information from the listed event, if available.
 * @param {object} track - The track object to be updated.
 * @param {string} specificity - The specificity string used in the key of the listed event.
 * @returns {object}
 */
function updateTrackWithListedEvent(track = {}, specificity = '') {
  if (track?.list_name) {
    // Get the listed event based on the specificity and track.list_name
    let listedEvent = events[`${specificity !== '' ? specificity + ' - ' : ''}${track.list_name}`]

    // If the listed event is not found, fall back to track.list_name
    listedEvent = listedEvent !== undefined ? listedEvent : events[track.list_name]

    // Merge the listed event properties with the track object
    track = { ...listedEvent, ...track }
    track = removeUnassigned(track)
    track = addPresets(track)
  }
  return track
}

export { updateTrackWithListedEvent }
