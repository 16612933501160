import { useTriggerEvents } from 'hooks/useTriggerEvents'

const useAnalyticsTrackEvent = () => {
  const { fireAndForget } = useTriggerEvents()

  const analyticsTrackEvent = (payload) => {
    if (!payload || payload.constructor.name !== 'Object') {
      console.warn('Analytics data must be an Object')
      return
    }
    return fireAndForget({
      ...payload,
    })
  }
  return { analyticsTrackEvent }
}

export { useAnalyticsTrackEvent }
